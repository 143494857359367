import {
  FETCH_THEMES,
  CREATE_THEME,
  EDIT_THEME,
  DELETE_THEME,
  createThemeSucceeded,
  editThemeSucceeded,
  deleteThemeSucceeded,
  setThemesList
} from './themesActions';
import { takeEvery, select, put } from 'redux-saga/effects';
import { t } from 'i18next';
import { currentUserSelector } from 'redux/user/currentUserSelector';
import { createSnackbar } from 'redux/ui/uiActions';
import {
  getAllThemes,
  createThemePost,
  editTheme,
  deleteTheme
} from 'apis/PlatformServiceApi';

export function* fetchThemesListSaga() {
  yield takeEvery(FETCH_THEMES, executeList);
}

function* executeList() {
  try {
    const user = yield select(currentUserSelector);
    var fieldServiceThemes = (yield getAllThemes(user)).data;
    yield put(setThemesList(fieldServiceThemes));
  } catch (e) {
    yield put(createSnackbar(t('content.themes.fetchthemeserror'), 'error'));
    console.log(e);
  }
}

export function* createThemeSaga() {
  yield takeEvery(CREATE_THEME, executeCreate);
}

export function* executeCreate(object) {
  try {
    const user = yield select(currentUserSelector);

    const createdThemeResponse = (yield createThemePost(
      user,
      object.themeDetails
    )).data;

    yield put(createSnackbar(t('content.createtheme.snackbar.text')));
    yield put(createThemeSucceeded(createdThemeResponse.result));
  } catch (exception) {
    console.error(exception);
    throw exception;
  }
}

export function* editThemeSaga() {
  yield takeEvery(EDIT_THEME, executeEdit);
}

function* executeEdit(object) {
  try {
    const themeDetails = object.themeDetails;
    const user = yield select(currentUserSelector);
    yield editTheme(user, themeDetails);
    yield put(createSnackbar(t('content.edittheme.snackbar.text')));
    yield put(editThemeSucceeded(themeDetails));
  } catch (exception) {
    console.error(exception);
    throw exception;
  }
}

export function* deleteThemeSaga() {
  yield takeEvery(DELETE_THEME, executeDelete);
}

function* executeDelete(action) {
  const theme = action.deleteObject.selectedRow;
  const user = yield select(currentUserSelector);

  try {
    yield deleteTheme(user, theme.id);
    yield put(createSnackbar(t('content.deletetheme.snackbar.text')));
    yield put(deleteThemeSucceeded(theme));
  } catch (exception) {
    console.error(exception);
  }
}
