import axios from 'axios';
import configFile from '../App_config.json';
import { getActiveTenant } from 'utils/tenantManager.js';

const createHeaderConfig = user => {
  return {
    headers: {
      Authorization: 'Bearer ' + user.token,
      'X-IMD-Tenant': getActiveTenant()
    }
  };
};

export const getTenantById = async (user, tenantId) => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.platformServiceApiUrl + 'tenant/' + tenantId,
    config
  );
};

export const getAllTenants = async user => {
  const config = createHeaderConfig(user);

  return axios.get(configFile.platformServiceApiUrl + 'tenant/', config);
};

export const editTenant = async (user, tenantData) => {
  const config = createHeaderConfig(user);

  return axios.put(
    configFile.platformServiceApiUrl + 'tenant/' + tenantData.id,
    tenantData,
    config
  );
};

export const createTenant = async (user, tenantData) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.platformServiceApiUrl + 'tenant/',
    tenantData,
    config
  );
};

export const deleteTenant = async (user, tenantId) => {
  const config = createHeaderConfig(user);

  return axios.delete(
    configFile.platformServiceApiUrl + 'tenant/' + tenantId,
    config
  );
};

export const getThemeById = async (user, themeId) => {
  const config = createHeaderConfig(user);

  return axios.get(
    configFile.platformServiceApiUrl + 'theme/' + themeId,
    config
  );
};

export const getThemeByTenantId = (user, tenant) => {
  const config = createHeaderConfig(user);
  return axios.get(
    configFile.platformServiceApiUrl + 'theme/tenant/' + tenant,
    config
  );
};

export const getAllThemes = async user => {
  const config = createHeaderConfig(user);

  return axios.get(configFile.platformServiceApiUrl + 'theme/', config);
};

export const editTheme = async (user, themeData) => {
  const config = createHeaderConfig(user);

  return axios.put(
    configFile.platformServiceApiUrl + 'theme/' + themeData.id,
    themeData,
    config
  );
};

export const createThemePost = async (user, themeData) => {
  const config = createHeaderConfig(user);

  return axios.post(
    configFile.platformServiceApiUrl + 'theme/',
    themeData,
    config
  );
};

export const deleteTheme = async (user, themeId) => {
  const config = createHeaderConfig(user);

  return axios.delete(
    configFile.platformServiceApiUrl + 'theme/' + themeId,
    config
  );
};
